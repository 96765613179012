<template>
  <div class="index">
    <div class="search">
      <div class="img">
        <img class="left" src="../assets/logo1.png" alt="" @click="gotoindex" />
        <span style="font-size: 50px;color: #8c0920;margin: 0 20px;">·</span>
        <img class="right" src="../assets/logo22.png" alt="" />
      </div>
      <div class="input">
        <el-autocomplete placeholder="请输入内容" v-model="input" clearable :fetch-suggestions="querySearch"
          :trigger-on-focus="false" @select="handleSelect" :popper-append-to-body="false"></el-autocomplete>
        <img src="../assets/WechatIMG49.jpg" alt="" @click="gotoci" />
      </div>
    </div>
  </div>
</template>

<script>
import { get_name } from "../api/index";
export default {
  name: "index",
  components: {},
  data() {
    return {
      input: "",
      restaurants: [],
    };
  },
  mounted() { },
  methods: {
    async get_name() {
      try {
        const response = await get_name({ word: this.input, type: 2 });
        console.log("response", response.data.code);
        if (response.data.code == 1) {
          return response.data.data;
        } else {
          this.$message.error(response.data.msg);
        }
      } catch (error) {
        this.$message.error("获取数据时出错:", error);
        console.error("获取数据时出错:", error);
        throw error;
      }
    },
    gotoci() {
      // this.$router.push(`/xun?word=${this.input}`);
      this.$router.push({ name: "ci", params: { word: this.input } });
    },
    gotoindex() {
      this.$router.push("/xun");
    },
    async querySearch(queryString, cb) {
      if (this.input != "") {
        let queryList = await this.get_name();
        // console.log("queryList", queryList);
        queryList = queryList.map((item) => {
          return { value: item.name, id: item.id };
        });
        cb(queryList);
      }
    },
    handleSelect(item) {
      console.log("item" + item);
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep.el-autocomplete-suggestion {
//   border: none !important;
//   background: #F8F8F8 !important;
//   box-shadow:none !important;
//   margin-top: -10px;
// }
::v-deep .el-input__inner {
  border-radius: 43.14px;
  height: 63.85px;
  background-color: #f8f8f8;
  border: none;
  font-size: 22px;
  width: 1020px;
  color: #000;
  padding-left: 50px;
}

::v-deep .el-autocomplete-suggestion li {
  padding-top: 20px;
}

::v-deep .el-popper[x-placement^="bottom"] {
  margin-top: 0;
  border: none;
  background: #f8f8f8;
  box-shadow: none;
  font-size: 22px;
  margin-top: -20px;
  margin-left: -5px;
  border-radius: 0 0 30px 30px;
  padding-bottom: 30px;
}

::v-deep .el-popper .popper__arrow::after {
  content: "";
  border-width: 0;
  background: #f8f8f8;
}

::v-deep .el-autocomplete-suggestion li {
  font-size: 22px;
  color: #000;
  padding-left: 50px;
}

::v-deep .el-autocomplete-suggestion li:hover {
  color: #8c0920;
  background: #f8f8f8;
}

.index {
  width: 100%;

  .search {
    width: 1019.75px;
    margin: 15% auto;

    .img {
      height: 89.18px;
      text-align: center;
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 90px;
      width: 250px;

      .left {
        cursor: pointer;
        height: 100%;
        //margin-right: 40px;
      }

      .right {
        cursor: pointer;
        height: 100%;
      }
    }

    .input {
      position: relative;

      img {
        cursor: pointer;
        position: absolute;
        right: 3%;
        top: 23%;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        /* 左边框 */
        border-bottom: 15px solid transparent;
        /* 右边框 */
        border-left: 20px solid #8c0920
          /* 上边框，颜色为红色 */
      }
    }
  }
}
</style>
