<template>
  <div id="app">
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>
<script>

import Footer from "./components/footer.vue";
export default {
  components: {
    Footer
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
page{
  font-family: PingFang SC;
}

</style>
