<template>
  <div class="xundetail">
    <img src="../assets/logo22.png" alt="" @click="back" />
    <div class="List">
      <el-input v-model="input" placeholder="请输入内容"></el-input>
      <img src="../assets/right.png" alt="" @click="goto" />
    </div>
    <div class="content">
      <div class="left">
        <div class="ABC">
          <div :class="item == choosezm ? 'actitve it' : 'it'" v-for="(item, index) in zm" :key="index"
            @click="get_zm(item)">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="right">
        <el-scrollbar style="height: 450px">
          <div class="item" v-if="word.length > 0">
            <div :class="item.sz == input ? 'actitve it' : 'it'" v-for="(item, index) in word" :key="index"
              @click="shuru(item.sz)">
              {{ item.sz }}
            </div>
          </div>
          <div class="item" v-else style="font-size: 22px; color: #919191">
            没有更多了
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { get_zm } from "../api/index";
export default {
  name: "xundetail",
  components: {},
  data() {
    return {
      input: this.$route.params.word,
      zm: [],
      word: [],
      choosezm: "A",
    };
  },
  mounted() {
    this.get_zm();
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    shuru(word) {
      if (this.input == undefined) {
        this.input = ''
        this.input = word;
      } else {
        this.input += word;
      }
    },
    goto() {
      this.$router.push({ name: "detail", params: { word: this.input } });
    },
    async get_zm(choosezm) {
      this.$loading()
      this.choosezm = choosezm;

      let res = await get_zm({ zm: choosezm || "A" });
      this.$loading().close()
      if (res.data.code == 1) {
        this.zm = res.data.data.zm;
        this.word = res.data.data.list;
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  border-radius: 43.14px;
  height: 63.85px;
  background-color: #f8f8f8;
  border: none;
  font-size: 22px;
  width: 1181px;
  color: #000;
  padding-left: 50px;
}

.xundetail {
  width: 1181px;
  margin: 10% auto 0;
  min-height: 504px;
  margin-bottom: 300px;

  img {
    cursor: pointer;
    width: 31px;
    height: 44px;
  }

  .List {
    margin-top: 30px;
    position: relative;

    img {
      position: absolute;
      right: 3%;
      top: 23%;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      /* 左边框 */
      border-bottom: 15px solid transparent;
      /* 右边框 */
      border-left: 20px solid #8c0920
        /* 上边框，颜色为红色 */
    }
  }

  .content {
    margin-top: 30px;
    display: flex;
    width: 100%;
    min-height: 450px;
    justify-content: space-between;
    flex-wrap: wrap;

    .left {
      width: 39%;
      background: #f9f9f9;
      border-radius: 10px;

      // padding: 30px;
      .ABC {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: 60px auto;

        .it {
          cursor: pointer;
          width: 12%;
          font-size: 24px;
          color: #919191;
          text-align: center;
          line-height: 80px;
        }

        .actitve {
          color: #8c0920;
        }
      }
    }

    .right {
      width: 59%;
      background: #f9f9f9;
      border-radius: 10px;
      height: 450px;

      .item {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: 40px auto;

        .it {
          cursor: pointer;
          width: 12%;
          line-height: 50px;
          font-size: 24px;
          color: #919191;
          text-align: center;
        }

        .actitve {
          color: #8c0920;
        }
      }
    }
  }
}
</style>