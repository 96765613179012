<template>
  <div class="xundetail">
    <img src="../assets/logo1.png" alt="" @click="back" />
    <div v-if="List.length > 0" class="List">
      <block v-for="(item, index) in List" :key="index">
        <div @click="change(index,item.image)" :class="index==ind?'item':'item2'">{{ item.name }}</div>
        <!-- <div class="content">
          <div class="title">{{ item.name }}</div>
          <div class="text">
            {{ item.type_text || "暂无简介" }}
          </div>
        </div> -->
      </block>
    </div>
    <div v-else>
      <div class="List">
        <div class="item">暂无信息</div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_name } from "../api/index";
export default {
  name: "xundetail",
  components: {},
  data() {
    return {
      List: [],
      ind:0
    };
  },
  mounted() {
    // console.log("666", this.$route.params.word);
    this.get_name();
  },
  methods: {
    change(i,img){
        this.ind = i,
        console.log('img',img)
        window.open(img, "_blank");
    },
    back() {
      this.$router.push("/");
    },
    async get_name() {
      const loading = this.$loading();
      try {
        const response = await get_name({ type: 1 });
        this.$loading().close();
        if (response.data.code == 1) {
          this.List = response.data.data;
          // console.log('List', this.List)
        } else {
          this.$message.error(response.data.msg);
        }
      } catch (error) {
        this.$loading().close();
        console.error("获取数据时出错:", error);
        this.$message.error("获取数据时出错:", error);

        throw error;
      }
    },
    goto() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.xundetail {
  width: 1181px;
  margin: 10% auto 0;
  // border: 1px solid red;
  min-height: 504px;
  margin-bottom: 300px;

  img {
    cursor: pointer;
    width: 31px;
    height: 44px;
  }

  .List {
    display: flex;
    width: 100%;
    justify-content: start;
    margin-top: 20px;
    flex-wrap: wrap;

    .item {
      cursor: pointer;
      background: #8c0920;
      border-radius: 50px;
      padding: 10px;
      color: #fff;
      width: 180px;
      text-align: center;
      font-size: 20px;
      margin-right: 10px;
      margin-top: 20px;
    }
    .item2{
      cursor: pointer;
      background: #f9f9f9;
      border-radius: 50px;
      padding: 10px;
      color: #000;
      width: 180px;
      text-align: center;
      font-size: 20px;
      margin-right: 10px;
      margin-top: 20px;
    }
    .item:nth-child(5){
      margin-right: 0;
    }
  }

  .content {
    background: #f9f9f9;
    padding: 40px;
    margin-top: 40px;
    border-radius: 10px;

    .title {
      font-size: 32px;
      color: #8c0920;
      margin-bottom: 20px;
      font-weight: 550;
    }

    .text {
      color: #616161;
      font-size: 21px;
      line-height: 41px;
      letter-spacing: 1px;
    }
  }
}
</style>