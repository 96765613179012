<template>
  <div class="footer">上海国开投资管理有限公司</div>
</template>

<script>
export default {
  name: "footer",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.footer {
  position: fixed;
  bottom: 0;
  height: 74px;
  background: #fafafa;
  width: 100%;
  display: block;
  text-align: center;
  line-height: 74px;
  font-size: 16px;
  color: #C9C9C9;
}
</style>
