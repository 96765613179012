import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index2',
    component: () => import(/* webpackChunkName: "about" */ '../views/index2.vue')
  },
  // {
  //   path: '/xun',
  //   name: 'xun',

  //   component: () => import(/* webpackChunkName: "about" */ '../views/xundetail.vue')
  // },
  {
    path: '/ci',
    name: 'ci',

    component: () => import(/* webpackChunkName: "about" */ '../views/cidetail.vue')
  },
  {
    path: '/detail',
    name: 'detail',

    component: () => import(/* webpackChunkName: "about" */ '../views/detail.vue')
  },
  {
    path: '/xun',
    name: 'xun',

    component: () => import(/* webpackChunkName: "about" */ '../views/xundetail.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
