<template>
  <div class="xundetail">
    <div class="content">
      <div class="left">
        <div class="list" v-if="wordList.length > 0">
          <div
            class="ABC"
            :class="e.id == chooseid ? 'active' : ''"
            v-for="(e, i) in wordList"
            :key="i"
            @click="word_detail(e.id)"
          >
            {{ e.name }}
          </div>
        </div>
        <div class="list" v-else>
          <div class="ABC">暂无信息</div>
        </div>
      </div>
      <div class="right">
        <div class="right_item">
          <img src="../assets/logo22.png" alt="" @click="back" />
          <div class="title">{{ worddetail.name || "暂无信息" }}</div>
          <div class="text">
            {{ worddetail.desc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
import { word_list, word_detail } from "../api/index";

export default {
  name: "xundetail",
  components: {},
  data() {
    return {
      input: "",
      wordList: [],
      worddetail: {},
      chooseid: null,
    };
  },
  async mounted() {
    await this.word_list();
    await this.word_detail();
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    async word_list() {
      this.$loading();

      let res = await word_list({ word: this.$route.params.word });
      this.$loading().close();

      if (res.data.code == 1) {
        this.wordList = res.data.data;
        this.chooseid = res.data.data[0].id;
      } else {
        this.$message.error(res.data.msg);
      }
    },
    async word_detail(id) {
      this.$loading();
      if (id) {
        this.chooseid = id;
      } else {
      }
      let res = await word_detail({ id: id || this.chooseid });
      this.$loading().close();

      if (res.data.code == 1) {
        this.worddetail = res.data.data;
      } else {
        this.$message.error(res.data.msg);
      }
    },
    goto() {
      this.$router.push("/");
    },
  },
};
</script>   
<style lang="scss" scoped>
.xundetail {
  width: 1181px;
  margin: 10% auto 0;
  // border: 1px solid red;
  min-height: 530px;
  margin-bottom: 300px;
  .content {
    margin-top: 30px;
    display: flex;
    width: 100%;
    min-height: 530px;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: 20%;
      background: #f9f9f9;
      border-radius: 10px;

      // padding: 30px;
      .list {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // border: 1px solid red;
        text-align: center;
        margin: 25px auto;
        cursor: pointer;
      }
      .ABC {
        // border: 1px solid rgb(157, 180, 24);
        line-height: 66px;
        font-size: 25px;
      }
      .active {
        color: #8c0920;
      }
    }
    .right {
      width: 78%;
      background: #f9f9f9;
      border-radius: 10px;
      min-height: 530px;
      .right_item {
        padding: 55px;
        img {
          cursor: pointer;
          width: 28px;
          height: 44px;
          margin-bottom: 20px;
        }
        .title {
          font-size: 32px;
          color: #8c0920;
          margin-bottom: 20px;
        }
        .text {
          font-size: 21px;
          color: #616161;
          line-height: 48px;
          letter-spacing: 2px;
        }
      }
    }
  }
}
</style>
      