import request from "@/utils/request.js";

//搜索寻1-2
export function get_name(data) {
  return request({
    url: `/api/Index/get_name`,
    method: "post",
    data: data
  });
}
//搜索伺1
export function get_zm(data) {
  return request({
    url: `/api/Index/get_zm`,
    method: "get",
    params: data
  });
}
//搜索伺2
export function word_list(data) {
  return request({
    url: `/api/Index/word_list`,
    method: "get",
    params: data
  });
}
//词语详情
export function word_detail(data) {
  return request({
    url: `/api/Index/word_detail`,
    method: "get",
    params: data
  });
}